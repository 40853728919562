<template>
<div>
  <Header />

  <div v-if="!validatedCode" class="login-register_area">
    <div class="container">
      <div class="row col-xs-12">
        <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 center-block" style="margin: auto;">
          <form action class="form" @submit.prevent="checkCode">
            <div class="login-form">
              <h4 class="login-title" style="text-transform: inherit;text-align: center;">
                Ingresa el código de recuperación que enviamos a tu correo
                electrónico
              </h4>
              <div class="row">
                <div class="col-md-12">
                  <label class="text-center">Código de recuperación *</label>
                </div>
                <div style="margin:auto;">
                  <input v-model="code" oninput="if(this.value.length > 4){this.value = this.value.slice(0,4);}" type="number" required min="1000" max="9999" style="padding:6px;width:100px; margin:auto; text-align: center;" placeholder="0000" />
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div style="margin:auto;">
                      <button type="submit" class="btn ht-btn--round">
                        Validar código
                      </button>
                      <!--<p
                          v-if="error"
                          style="padding-top: 10px;"
                          class="error"
                        >
                          Ocurrió un error, intente de nuevo por favor.
                        </p>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div v-if="validatedCode" class="login-register_area">
    <div class="container">
      <div class="row col-xs-12">
        <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 center-block" style="margin: auto;">
          <form action class="form" @submit.prevent="checkPassword">
            <div class="login-form">
              <h4 class="login-title" style="text-transform: inherit;text-align: center;">
                Ingresa y confirma tu nueva contraseña
              </h4>
              <div class="row">
                <div class="col-md-6">
                  <label>Contraseña *</label>
                  <input v-model="password" id="password" required type="password" minlength="8" placeholder="Ingresa tu contraseña" />
                </div>
                <div class="col-md-6">
                  <label>Confirmación de contraseña *</label>
                  <input v-model="passwordConfirmation" id="confirmation" required minlength="8" type="password" placeholder="Confirma tu contraseña" />
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div style="margin:auto;">
                      <button type="submit" class="btn ht-btn--round">
                        Restablecer contraseña
                      </button>
                      <!--<p
                          v-if="error"
                          style="padding-top: 10px;"
                          class="error"
                        >
                          Ocurrió un error, intente de nuevo por favor.
                        </p>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <BrandCarousel />

  <Footer />

  <OffCanvasMobileMenu />

  <!-- back to top start -->
  <back-to-top class="scroll-top" bottom="60px">
    <i class="ion-android-arrow-up"></i>
  </back-to-top>
  <!-- back to top end -->
</div>
</template>

<script>
import Header from "@/components/Header";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";
import axios from "axios";

export default {
  name: "Recovery",
  data: () => ({
    email: "",
    code: "",
    password: "",
    passwordConfirmation: "",
    validatedCode: false,
    error: false
  }),
  components: {
    Header,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu
  },
  methods: {
    checkCode() {
      var urlRecoveryFind = process.env.VUE_APP_AUTH_API + "api/password/find/";
      axios
        .get(urlRecoveryFind + this.code)
        .then(response => {
          this.email = response.data.email;
          this.validatedCode = true;
        })
        .catch(error => {
          this.$swal({
            title: "Código inválido",
            text: "No hemos encontrado usuarios asociados al código de recuperación que ingresaste en nuestros registros",
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#e94e1b",
            showConfirmButton: true
          });
          this.$swal.showValidationMessage(`Solicitud rechazada: ${error}`);
          setTimeout(() => location.reload, 5000);
          console.log(error);
          this.error = true;
        });
    },
    checkPassword() {
      if (this.password == this.passwordConfirmation) {
        this.setPassword();
      } else {
        this.$swal({
          title: "Falló la confirmación de la contraseña",
          text: "La confirmación de la contraseña no coincide. Por favor, intenta nuevamente",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#e94e1b",
          showConfirmButton: true
        });
      }
    },
    setPassword() {
      var urlSetPassword = process.env.VUE_APP_AUTH_API + "api/password/reset";
      axios
        .post(urlSetPassword, {
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          token: this.code
        })
        .then(response => {
          this.$swal({
            title: "Proceso completado",
            text: "Haz restablecido tu contraseña exitosamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#003d74",
            showConfirmButton: true
          });
          this.$router.push("/login");
        })
        .catch(error => {

          console.log(error);
          this.error = true;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 20px;
  background-color: #003d74;
}

.login-form input {
  padding: 6px;
}
</style>
